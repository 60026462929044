import React, {useEffect, useState} from 'react';
import Navbar from "../../ui/Navbar";
import {
    MDBBtn, MDBBtnGroup,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";

import axios from "axios";
import {BASE_URL, PHOTO_URL} from "../../utils/instance/Base";
import instance from "../../utils/instance";
import {ErrorNotify, SuccessNotify} from "../../alerts";
import {Pagination} from "@mui/material";
import format from "../../utils/instance/format";

function Index(props) {

    const [basicModal, setBasicModal] = useState(false);

    const toggleOpen = () => {
        setName('')
        setFiles([])
        setSellPrice('')
        setPurchasePrice('')
        setDescription('')
        setBasicModal(!basicModal)
        setCurrentProduct(null)
    }

    const [files, setFiles] = useState([])
    const [name, setName] = useState('')
    const [sellPrice, setSellPrice] = useState('')
    const [purchasePrice, setPurchasePrice] = useState('')
    const [description, setDescription] = useState('')
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState('')
    const [products, setProducts] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [currentProduct, setCurrentProduct] = useState(null)

    async function getProducts(page, search) {
        const res = await instance(`/product?page=${page}&search=${search}`, "GET");
        if (res.data.ok) {
            setProducts(res.data.data.content)
            setTotalPages(res.data.data.totalPages)
        } else {
            ErrorNotify(res.data.message)
        }
    }

    useEffect(() => {
        getCategories()
        getProducts(1, "")
    }, []);

    const handleUpload = async (event) => {
        const formData = new FormData();
        for (let i = 0; i < event.target.files.length; i++) {
            formData.append('file', event.target.files[i]);
        }
        try {
            const response = await axios.post(`${BASE_URL}/attachment/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': localStorage.getItem("@lsh_id_session")
                }
            });
            if (response.data.ok) {
                setFiles(response.data.data)
            }
        } catch (error) {
            setFiles([])
            console.error('Fayllarni yuklashda xatolik yuz berdi:', error);
        }
    };

    async function getCategories() {
        const res = await instance("/category/all", "GET");
        if (res.data.ok) {
            setCategories(res.data.data)
        } else {
            ErrorNotify(res.data.message)
        }
    }

    async function saveProduct(e) {
        e.preventDefault()
        if (currentProduct === null) {
            const res = await instance("/product", "POST", {
                name,
                sellPrice,
                purchasePrice,
                description,
                files,
                category
            });
            if (res.data.ok) {
                SuccessNotify(res.data.message)
                toggleOpen()
                getProducts(1, "")
            } else {
                ErrorNotify(res.data.message)
            }
        } else {
            const res = await instance(`/product/${currentProduct?.id}`, "PUT", {
                name,
                sellPrice,
                purchasePrice,
                description,
                files,
                category
            });
            if (res.data.ok) {
                SuccessNotify(res.data.message)
                toggleOpen()
                getProducts(1, "")
                setCurrentProduct(null)
            } else {
                ErrorNotify(res.data.message)
            }
        }
    }

    async function deleteProduct(id) {
        const res = await instance(`/product/${id}`, "DELETE");
        if (res.data.ok) {
            SuccessNotify(res.data.message)
            getProducts(1, "")
        } else {
            ErrorNotify(res.data.message)
        }
    }

    function updateProduct(item) {
        toggleOpen()
        setCurrentProduct(item)
        setName(item?.name)
        setSellPrice(item?.sellPrice)
        setPurchasePrice(item?.purchasePrice)
        setDescription(item?.description)
        setCategory(item?.categoryId)
        setFiles(item?.attachments)
    }


    return (
        <div style={{width: "100%", height: "100vh"}}><Navbar/>
            <div style={{width: "100%", padding: 15}}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                    <MDBBtn className={'mb-3'} onClick={toggleOpen}>MAHSULOT QO'SHISH <MDBIcon fas
                                                                                               icon="plus"/></MDBBtn>
                    <input onChange={(e) => getProducts(1, e.target.value)} type="text" className={'form-control w-25'}
                           placeholder={'Mahsulotni izlash'}/>
                </div>
                {products.length > 0 ?
                    <div style={{width: "100%", overflowY: "auto"}}>
                        <MDBTable>
                            <MDBTableHead light>
                                <tr>
                                    <th scope='col'>#</th>
                                    <th scope='col'>Rasm</th>
                                    <th scope='col'>Nomi</th>
                                    <th scope='col'>Sotib Olish Narxi</th>
                                    <th scope='col'>Sotish Narxi</th>
                                    <th scope='col'>Tavsifi</th>
                                    <th scope='col'>Turkum</th>
                                    <th scope='col'>Amallar</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {products?.map((item, index) => (
                                    <tr key={item?.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {item?.attachments?.map((p, i) => (
                                                <img style={{width: 25, height: 25, borderRadius: "50%"}} key={p}
                                                     src={PHOTO_URL + p} alt={p}/>
                                            ))}
                                        </td>
                                        <td>{item?.name}</td>
                                        <td>{format(item?.purchasePrice)}</td>
                                        <td>{format(item?.sellPrice)}</td>
                                        <td>
                                            <pre>{item?.description}</pre>
                                        </td>
                                        <td>{item?.categoryName}</td>
                                        <td><MDBBtnGroup shadow='0'>
                                            <MDBBtn onClick={() => updateProduct(item)} color='success'>
                                                <MDBIcon fas icon="pen"/>
                                            </MDBBtn>
                                            <MDBBtn onClick={() => deleteProduct(item?.id)} color='danger'>
                                                <MDBIcon fas icon="trash"/>
                                            </MDBBtn>
                                        </MDBBtnGroup></td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </div> : <h3>Mahsulot topilmadi!</h3>}
                {totalPages > 1 ? <div style={{
                    marginTop: 20,
                    width: "100%",
                    padding: 7,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Pagination onChange={(event, page) => getProducts(page, "")} count={totalPages} color="primary"/>
                </div> : ""}

            </div>


            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Modal title</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={saveProduct} id={'save'}>
                            <MDBModalBody>
                                <input required={!files} multiple onChange={handleUpload} accept="image/*" type="file"
                                       className={'form-control mb-3'}/>
                                <input value={name} onChange={(e) => setName(e.target.value)} minLength={4} required
                                       type="text" className={'form-control mb-3'}
                                       placeholder={'Mahsulot nomi'}/>
                                <input value={purchasePrice} onChange={(e) => setPurchasePrice(e.target.value)}
                                       minLength={4} required type="number" className={'form-control mb-3'}
                                       placeholder={'Mahsulot sotib olish narxi'}/>
                                <input value={sellPrice} onChange={(e) => setSellPrice(e.target.value)} minLength={4}
                                       required type="number" className={'form-control mb-3'}
                                       placeholder={'Mahsulot sotish narxi'}/>
                                <textarea value={description} onChange={(e) => setDescription(e.target.value)}
                                          minLength={4} required placeholder={'Mahsulot tavsifi'}
                                          className={'form-control mb-3'}></textarea>
                                <select value={category} onChange={(e) => setCategory(e.target.value)} required={true}
                                        className={'form-control'}>
                                    <option value="" disabled selected>Turkumni tanlang</option>
                                    {categories?.map((item) => <option key={item?.id}
                                                                       value={item?.id}>{item?.name}</option>)}
                                </select>
                            </MDBModalBody>
                        </form>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn form={'save'} type={'submit'}>SAQLASH</MDBBtn>
                        </MDBModalFooter>

                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;