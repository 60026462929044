import React, {useEffect, useState} from 'react';
import Navbar from "../../ui/Navbar";
import {
    MDBBtn,
    MDBIcon,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter, MDBBtnGroup,
} from 'mdb-react-ui-kit';
import {MDBBadge, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';

import {inputPng} from "../../images";
import axios from "axios";
import {BASE_URL, PHOTO_URL} from "../../utils/instance/Base";
import instance from "../../utils/instance";
import {ErrorNotify, SuccessNotify} from "../../alerts";

function Index(props) {
    const [file, setFile] = useState('')
    const [basicModal, setBasicModal] = useState(false);
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [path, setPath] = useState('')
    const [banners, setBanners] = useState([])
    const [currentBanner, setCurrentBanner] = useState(null)
    const toggleOpen = () => {
        setTitle('')
        setDescription('')
        setPath('')
        setFile('')
        setCurrentBanner(null)
        setBasicModal(!basicModal);
    }

    async function getBanners() {
        const res = await instance("/banner/s", "GET");
        if (res?.data?.ok) {
            setBanners(res?.data?.data)
        }
    }

    useEffect(() => {
        getBanners()
    }, []);
    const handleUpload = async (event) => {
        const formData = new FormData();
        for (let i = 0; i < event.target.files.length; i++) {
            formData.append('file', event.target.files[i]);
        }
        try {
            const response = await axios.post(`${BASE_URL}/attachment/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': localStorage.getItem("@lsh_id_session")
                }
            });
            if (response.data.ok) {
                setFile(response.data.data[0])
            }
        } catch (error) {
            setFile('')
            console.error('Fayllarni yuklashda xatolik yuz berdi:', error);
        }
    };

    async function handleSaveBanner(e) {
        e.preventDefault()
        if (currentBanner === null) {
            const res = await instance("/banner", "POST", {attachmentId: file, description, title, path});
            if (res?.data?.ok) {
                SuccessNotify(res?.data?.message)
                await getBanners()
                toggleOpen()
            } else {
                ErrorNotify(res?.data?.message)
            }
        } else {
            const res = await instance(`/banner/${currentBanner?.id}`, "PUT", {
                attachmentId: file,
                description,
                title,
                path
            });
            if (res?.data?.ok) {
                SuccessNotify(res?.data?.message)
                await getBanners()
                toggleOpen()
            } else {
                ErrorNotify(res?.data?.message)
            }
        }
    }

    async function deleteBanner(id) {
        const res = await instance(`/banner/${id}`, "DELETE");
        if (res?.data?.ok) {
            SuccessNotify(res?.data?.message)
            await getBanners()
        } else {
            ErrorNotify(res?.data?.message)
        }
    }

    function editBanner(item) {
        toggleOpen()
        setCurrentBanner(item)
        setTitle(item?.title)
        setPath(item?.path)
        setDescription(item?.description)
        setFile(item?.attachmentId)
    }

    return (
        <div><Navbar/>
            <div style={{width: "100%", padding: 15}}>
                <MDBBtn onClick={toggleOpen}>BANNER QO'SHISH <MDBIcon fas icon="plus-square"/></MDBBtn>
            </div>
            {banners?.length !== 0 ? <div style={{width: "100%", padding: 10, overflowY: "auto"}}>
                <MDBTable align='middle'>
                    <MDBTableHead>
                        <tr>
                            <th scope='col'>BANNER RASMI</th>
                            <th scope='col'>BANNER SARLAVHASI</th>
                            <th scope='col'>BANNER TAVSIFI</th>
                            <th scope='col'>BANNER YO'LI</th>
                            <th scope='col'>AMALLAR</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {banners?.map((item) => <tr key={item?.id}>
                            <td>
                                <img
                                    src={PHOTO_URL + item?.attachmentId}
                                    alt={item?.title}
                                    style={{width: '45px', height: '45px'}}
                                    className='rounded-circle'
                                />
                            </td>
                            <td>
                                <p className='fw-normal mb-1'>{item?.title}</p>
                            </td>
                            <td>
                                <p className='fw-normal mb-1'>{item?.description}</p>
                            </td>
                            <td>
                                <MDBBadge color='success' pill>
                                    {item?.path}
                                </MDBBadge>
                            </td>
                            <td>
                                <MDBBtnGroup shadow='0' aria-label='Basic example'>
                                    <MDBBtn onClick={() => editBanner(item)} color='success' outline>
                                        <MDBIcon fas icon="pen"/>
                                    </MDBBtn>
                                    <MDBBtn onClick={() => deleteBanner(item?.id)} color='danger' outline>
                                        <MDBIcon fas icon="trash"/>
                                    </MDBBtn>
                                </MDBBtnGroup>
                            </td>
                        </tr>)}
                    </MDBTableBody>
                </MDBTable>
            </div> : ""}


            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>BANNER</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <form id={'save-banner'} onSubmit={handleSaveBanner}>
                            <MDBModalBody>
                                <label className={'mb-3'}>
                                    {file ? <img src={PHOTO_URL + file} alt="HAQNAZAROV.UZ"
                                                 style={{
                                                     width: 70,
                                                     height: 70,
                                                     cursor: "pointer"
                                                 }}/> : <img src={inputPng} alt="HAQNAZAROV.UZ"
                                                             style={{
                                                                 width: 70,
                                                                 height: 70,
                                                                 cursor: "pointer"
                                                             }}/>}
                                    <input required={!file} onChange={handleUpload} type="file" hidden
                                           accept="image/*"/>
                                </label>
                                <input value={title} onChange={(e) => setTitle(e.target.value)} type="text"
                                       placeholder={"Sarlavha"} className={'form-control mb-3'} required/>
                                <input value={path} onChange={(e) => setPath(e.target.value)} type="text"
                                       placeholder={"Yo'lni kiriting"} className={'form-control mb-3'}
                                       required/>
                                <textarea value={description} onChange={(e) => setDescription(e.target.value)} required
                                          className={'form-control'} style={{minHeight: 120}}
                                          placeholder={"Tavsif kiriting"}></textarea>
                            </MDBModalBody>
                        </form>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn type={'submit'} form={'save-banner'}>SAQLASH</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;