import React, {useEffect, useState} from 'react';
import Navbar from "../../ui/Navbar";
import instance from "../../utils/instance";
import {
    MDBBadge,
    MDBBtn,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBIcon,
    MDBModal,
    MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter
} from 'mdb-react-ui-kit';
import {ErrorNotify, SuccessNotify} from "../../alerts";
import format from "../../utils/instance/format";
import {Pagination} from "@mui/material";

function Index(props) {
    const [orders, seOrders] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [orderId, setOrderId] = useState('')
    const [basicModal, setBasicModal] = useState(false);
    const [products, setProducts] = useState([])
    const toggleOpen = () => setBasicModal(!basicModal);

    async function getOrders(page = 1) {
        const res = await instance(`/order/all?page=${page}&orderId=${orderId}`, "GET");
        if (res.data.ok) {
            seOrders(res.data.data.content)
            setTotalPages(res.data.data.totalPages)
        } else {
            ErrorNotify(res.data.message)
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getOrders();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getOrders()
    }, [orderId]);


    async function changeStatus(id, status) {
        const res = await instance("/order/change-status", "POST", {id, status});
        if (res.data.ok) {
            SuccessNotify(res.data.message)
            await getOrders()
        } else {
            ErrorNotify(res.data.message)
        }
    }

    async function getProducts(id) {
        const res = await instance(`/order/items/${id}`, "GET");
        if (res.data.ok) {
            setProducts(res.data.data)
            toggleOpen()
        } else {
            ErrorNotify(res.data.message)
        }
    }

    return (
        <div>
            <Navbar/>
            <div style={{width: "100%", padding: 15}}>
                <input value={orderId} onChange={(e) => setOrderId(e.target.value)} type="number"
                       placeholder={'Buyurtma raqami'} className={'form-control'}/>
            </div>
            {orders?.length !== 0 ? <div style={{width: "100%", padding: 5, overflowY: "auto"}}>
                <MDBTable align='middle'>
                    <MDBTableHead>
                        <tr>
                            <th scope='col'>BUYURTMA RAQAMI</th>
                            <th scope='col'>BUYURTMA BERUVCHI</th>
                            <th scope='col'>BUYURTMA MANZILI</th>
                            <th scope='col'>BUYURTMA HOLATI</th>
                            <th scope='col'>TELEFON RAQAM</th>
                            <th scope='col'>JAMI SUMMA</th>
                            <th scope='col'>MAHSULOTLAR</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {orders?.map((item) => <tr key={item?.id}>
                            <td>
                                <p className='fw-bold mb-1'>{item?.orderId}</p>
                            </td>
                            <td>
                                <p className='fw-normal mb-1'>{item?.firstName}</p>
                                <p className='text-muted mb-0'>{item?.lastName}</p>
                            </td>
                            <td>
                                <p className='fw-normal mb-1'>{item?.address}</p>
                                <p className='text-muted mb-0'>{item?.region}</p>
                            </td>
                            <td>
                                <select onChange={(e) => changeStatus(item?.id, e.target.value)} style={{width: 200}}
                                        defaultValue={item?.status} className={'form-select'}>
                                    <option value="" disabled selected>BUYURTMA HOLATINI BELGILANG</option>
                                    <option value="PENDING">KUTILMOQDA</option>
                                    <option value="CONFIRMED">TASDQILANDI</option>
                                    <option value="SHIPPING">YETKAZILMOQDA</option>
                                    <option value="DELIVERED">YETKAZIB BERILDI</option>
                                    <option value="CANCELLED">BEKOR QILINDI</option>
                                </select>
                            </td>
                            <td>{item?.phone}</td>
                            <td>{format(item?.totalPrice)} UZS</td>
                            <td>
                                <MDBBtn onClick={() => getProducts(item?.id)} color='link' rounded size='sm'>
                                    <MDBIcon fas icon="info"/>
                                </MDBBtn>
                            </td>
                        </tr>)}
                    </MDBTableBody>
                </MDBTable>
            </div> : ""}
            {totalPages > 1 ? <div
                style={{width: "100%", padding: 15, display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Pagination count={totalPages} onChange={(event, page) => getOrders(page)} color="primary"/>
            </div> : ""}


            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>MAHSULOTLAR RO'YXATI</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBTable>
                                <MDBTableHead>
                                    <tr>
                                        <th scope='col'>#</th>
                                        <th scope='col'>Mahsulot nomi</th>
                                        <th scope='col'>Mahsulot narxi</th>
                                        <th scope='col'>Mahsulot donasi</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {products?.map((item, index) => <tr key={item?.id}>
                                        <th scope='row'>{index + 1}</th>
                                        <td>{item?.productName}</td>
                                        <td>{format(item?.price)} UZS</td>
                                        <td>{item?.quantity}</td>
                                    </tr>)}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;