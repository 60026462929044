export default function format(amount) {
    const germanCurrencyFormatter = new Intl.NumberFormat("uz", {notation: "standard"});
    return germanCurrencyFormatter.format(amount);
}

export function formatDateYear(ms) {
    let date = new Date(ms)
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `${year}/${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}`
}
