import React, {useState} from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBCheckbox, MDBCol, MDBContainer, MDBInput, MDBRow} from 'mdb-react-ui-kit';
import axios from "axios";
import {BASE_URL} from "../../utils/instance/Base";
import {useNavigate} from "react-router-dom";


function Login() {
    const navigate = useNavigate()
    const [error, setError] = useState('')

    function login(e) {
        e.preventDefault()
        let phone = e.target[0].value;
        phone = phone.replaceAll(" ", "");
        const password = e.target[1].value;
        const remember = e.target[2].checked;
        axios({
            url: BASE_URL + "/auth/login",
            method: "POST",
            data: {phone, password, remember}
        }).then((res) => {
            if (res.data.ok) {
                localStorage.setItem("@lsh_id_session", res.data.data.access_token)
                localStorage.setItem("@lsh_id_refresh_session", res.data.data.refresh_token)
                localStorage.setItem("@login_data", new Date())
                localStorage.setItem("@device", navigator.userAgent)
                localStorage.setItem("@dev", "haqnazarov.uz")
                navigate('/')
            } else {
                setError(res.data.message)
            }
        }).catch((err) => {
            console.error(err)
        })
    }

    return (
        <>
            <MDBContainer style={{background: "#00610E", height: "100vh"}} fluid>

                <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                    <MDBCol col='12'>

                        <MDBCard className='bg-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '500px'}}>
                            <form onSubmit={login}>
                                <MDBCardBody className='p-5 w-100 d-flex flex-column'>

                                    <h2 className="fw-bold mb-2 text-center">TIZIMGA KIRISH</h2>
                                    <p className="text-danger mb-3">{error}</p>

                                    <MDBInput minLength={13} defaultValue={'+998'} required={true}
                                              wrapperClass='mb-4 w-100'
                                              label='Telefon raqam' id='formControlLg' type='tel'
                                              size="lg"/>
                                    <MDBInput minLength={8} required={true} wrapperClass='mb-4 w-100' label='Parol'
                                              id='formControlLg'
                                              type='password'
                                              size="lg"/>

                                    <MDBCheckbox name='flexCheck' id='flexCheckDefault' className='mb-4'
                                                 label='Eslab qol'/>

                                    <MDBBtn style={{background: "#00610E"}} size='lg'>
                                        KIRISH
                                    </MDBBtn>

                                </MDBCardBody>
                            </form>
                        </MDBCard>

                    </MDBCol>
                </MDBRow>

            </MDBContainer></>
    );
}

export default Login;