import React, {useEffect, useState} from 'react';
import Navbar from "../../ui/Navbar";
import {
    MDBBtn, MDBBtnGroup,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
} from 'mdb-react-ui-kit';
import instance from "../../utils/instance";
import {ErrorNotify, SuccessNotify} from "../../alerts";
import {MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';

function Index(props) {
    const [basicModal, setBasicModal] = useState(false);
    const [name, setName] = useState('')
    const [types, setTypes] = useState([])
    const [bottomModal, setBottomModal] = useState(false);
    const [costTypeId, setCostTypeId] = useState('')
    const [currentCostType, setCurrentCostType] = useState(null)
    const toggleOpen = () => {
        setBasicModal(!basicModal);
        setName('')
        setCurrentCostType(null)
    }

    const toggleOpenVerify = () => setBottomModal(!bottomModal);

    async function getCostTypes() {
        const res = await instance("/cost-type", "GET");
        if (res?.data?.ok) {
            setTypes(res?.data?.data)
        }
    }

    useEffect(() => {
        getCostTypes()
    }, []);

    async function saveCostType(e) {
        e.preventDefault()
        if (currentCostType === null) {
            const res = await instance("/cost-type", "POST", {name});
            if (res?.data?.ok) {
                SuccessNotify(res?.data?.message)
                await getCostTypes()
                toggleOpen()
            } else {
                ErrorNotify(res?.data?.message)
            }
        } else {
            const res = await instance(`/cost-type/${currentCostType?.id}`, "PUT", {name});
            if (res?.data?.ok) {
                SuccessNotify(res?.data?.message)
                await getCostTypes()
                toggleOpen()
            } else {
                ErrorNotify(res?.data?.message)
            }
        }
    }

    async function deleteCostType() {
        const res = await instance(`/cost-type/${costTypeId}`, "DELETE");
        if (res?.data?.ok) {
            SuccessNotify(res?.data?.message)
            await getCostTypes();
            toggleOpenVerify()
        } else {
            ErrorNotify(res?.data?.message)
        }
    }

    function dellCostType(id) {
        setCostTypeId(id)
        toggleOpenVerify()
    }

    function updateCostType(item) {
        toggleOpen()
        setCurrentCostType(item)
        setName(item?.name)
    }

    return (
        <div><Navbar/>
            <div style={{width: "100%", padding: 10}}>
                <MDBBtn className={'mb-3'} onClick={toggleOpen}>XARAJAT TURI QO'SHISH <MDBIcon fas
                                                                                               icon="plus"/></MDBBtn>
                {types?.length === 0 ? <h4>Xarajat turi mavjud emas</h4> :
                    <div style={{width: "100%", overflowY: "auto"}}>
                        <MDBTable>
                            <MDBTableHead light>
                                <tr>
                                    <th scope='col'>#</th>
                                    <th scope='col'>NOMI</th>
                                    <th scope='col'>AMALLAR</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {types?.map((item, index) => <tr key={item?.id}>
                                    <th scope='row'>{index + 1}</th>
                                    <td>{item?.name}</td>
                                    <td>
                                        <MDBBtnGroup shadow='0'>
                                            <MDBBtn onClick={() => updateCostType(item)} color='success'>
                                                <MDBIcon fas icon="pen"/>
                                            </MDBBtn>
                                            <MDBBtn onClick={() => dellCostType(item?.id)} color='danger'>
                                                <MDBIcon fas icon="trash"/>
                                            </MDBBtn>
                                        </MDBBtnGroup>
                                    </td>
                                </tr>)}
                            </MDBTableBody>
                        </MDBTable>
                    </div>}
            </div>


            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>XARAJAT TURI</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={saveCostType} id={'save-cost-type'}>
                            <MDBModalBody>
                                <input value={name} onChange={(e) => setName(e.target.value)} required type="text"
                                       placeholder={'Xarajat nomi'} className={'form-control'}/>
                            </MDBModalBody>
                        </form>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn type={'submit'} form={'save-cost-type'}>SAQLASH</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


            <MDBModal animationDirection='bottom' open={bottomModal} tabIndex='-1'
                      onClose={() => setBottomModal(false)}>
                <MDBModalDialog position='bottom' frame>
                    <MDBModalContent>
                        <MDBModalBody className='py-1'>
                            <div className='d-flex justify-content-center align-items-center my-3'>
                                <p className='mb-0'>Siz xarajat turini o'chirishingiz bilan turkumga oid barcha
                                    xarajatlar
                                    o'chadi!</p>
                                <MDBBtn size='sm' className='ms-2' onClick={toggleOpenVerify}>
                                    BEKOR QILISH
                                </MDBBtn>
                                <MDBBtn onClick={deleteCostType} color={'danger'} size='sm' className='ms-2'>
                                    O'CHIRISHNI
                                    TASDIQLASH
                                </MDBBtn>
                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;