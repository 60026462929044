import React, {useEffect, useState} from 'react';
import Navbar from "../../ui/Navbar";
import {
    MDBBtn, MDBBtnGroup,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle
} from "mdb-react-ui-kit";
import {MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';

import instance from "../../utils/instance";
import {ErrorNotify, SuccessNotify} from "../../alerts";

function Index(props) {
    const [basicModal, setBasicModal] = useState(false);
    const [name, setName] = useState('')
    const [categories, setCategories] = useState([])
    const [currentCategory, setCurrentCategory] = useState(null)
    const [delId, setDelId] = useState('')
    const toggleOpen = () => {
        setName('')
        setBasicModal(!basicModal);
        setCurrentCategory(null)
    }
    const [bottomModal, setBottomModal] = useState(false);

    const toggleOpenVerify = () => setBottomModal(!bottomModal);

    async function getCategories() {
        const res = await instance("/category/all", "GET");
        if (res.data.ok) {
            setCategories(res.data.data)
        } else {
            ErrorNotify(res.data.message)
        }
    }

    useEffect(() => {
        getCategories();
    }, []);

    async function saveCategory() {
        if (currentCategory === null) {
            const res = await instance("/category", "POST", {name});
            if (res.data.ok) {
                SuccessNotify(res.data.message)
                toggleOpen()
                getCategories()
            } else {
                ErrorNotify(res.data.message)
            }
        } else {
            const res = await instance(`/category/${currentCategory?.id}`, "PUT", {name});
            if (res.data.ok) {
                SuccessNotify(res.data.message)
                toggleOpen()
                getCategories()
                setCurrentCategory(null)
            } else {
                ErrorNotify(res.data.message)
            }
        }
    }

    async function deleteCategory(id) {
        toggleOpenVerify()
        setDelId(id)

    }

    function editCategory(item) {
        toggleOpen()
        setCurrentCategory(item)
        setName(item?.name)
    }

    async function deleteCategoryOk() {
        toggleOpenVerify()
        const res = await instance(`/category/${delId}`, "DELETE");
        if (res.data.ok) {
            SuccessNotify(res.data.message)
            await getCategories()
            setDelId('')
        } else {
            ErrorNotify(res.data.message)
        }
    }

    return (
        <div style={{width: "100%", height: "100vh"}}>
            <Navbar/>
            <div style={{width: "100%", padding: 15}}>
                <MDBBtn className={"mb-3"} onClick={toggleOpen}>TURKUM QO'SHISH <MDBIcon fas icon="plus"/></MDBBtn>
                {categories.length > 0 ? <MDBTable>
                    <MDBTableHead light>
                        <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>Nomi</th>
                            <th scope='col'>Amallar</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {categories?.map((item, index) => <tr key={item?.id}>
                            <th scope='row'>{index + 1}</th>
                            <td>{item?.name}</td>
                            <td><MDBBtnGroup shadow='0'>
                                <MDBBtn onClick={() => editCategory(item)} color='success'>
                                    <MDBIcon fas icon="pen"/>
                                </MDBBtn>
                                <MDBBtn onClick={() => deleteCategory(item?.id)} color='danger'>
                                    <MDBIcon fas icon="trash"/>
                                </MDBBtn>
                            </MDBBtnGroup></td>
                        </tr>)}
                    </MDBTableBody>
                </MDBTable> : <h3>Turkumlar qo'shilmagan</h3>}
            </div>


            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>TURKUMLAR</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <input value={name} onChange={(e) => setName(e.target.value)} type="text"
                                   className={'form-control mb-3'} placeholder={'Turkum nomi'}/>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn onClick={saveCategory}>SAQLASH</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


            <MDBModal animationDirection='bottom' open={bottomModal} tabIndex='-1'
                      onClose={() => setBottomModal(false)}>
                <MDBModalDialog position='bottom' frame>
                    <MDBModalContent>
                        <MDBModalBody className='py-1'>
                            <div className='d-flex justify-content-center align-items-center my-3'>
                                <p className='mb-0'>Siz turkumni o'chirishingiz bilan turkumga oid barcha mahsulotlar
                                    o'chadi!</p>
                                <MDBBtn size='sm' className='ms-2' onClick={toggleOpenVerify}>
                                    BEKOR QILISH
                                </MDBBtn>
                                <MDBBtn onClick={deleteCategoryOk} color={'danger'} size='sm' className='ms-2'>
                                    O'CHIRISHNI
                                    TASDIQLASH
                                </MDBBtn>
                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;