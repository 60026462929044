import React, {useEffect, useState} from 'react';
import Navbar from "../../ui/Navbar";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBadge,
    MDBTable,
    MDBTableHead,
    MDBTableBody, MDBIcon, MDBBtnGroup
} from 'mdb-react-ui-kit';
import PhoneInput from "react-phone-input-2";
import instance from "../../utils/instance";
import {ErrorNotify, SuccessNotify} from "../../alerts";
import format from "../../utils/instance/format";
import {Pagination} from "@mui/material";
import {useNavigate} from "react-router-dom";

function Index(props) {
    const navigate = useNavigate()
    const [customer, setCustomer] = useState('')
    const [phone, setPhone] = useState('')
    const [basicModal, setBasicModal] = useState(false);
    const [basicModal2, setBasicModal2] = useState(false);
    const [customers, setCustomers] = useState([])
    const [searchCustomer, setSearchCustomer] = useState('')
    const [totalPages, setTotalPages] = useState(1)
    const [editeItem, setEditeItem] = useState('')
    const [currentItem, setCurrentItem] = useState('')
    const [payAmount, setPayAmount] = useState(0)
    const [description, setDescription] = useState('')
    const [selectedDebtOption, setSelectedDebtOption] = useState("CASH");

    const handleDebtOptionChange = (event) => {
        setSelectedDebtOption(event.target.value);
    };
    const toggleOpen = () => {
        setBasicModal(!basicModal)
        setEditeItem('')
        setCustomer('')
        setPhone('')
    };
    const toggleOpen2 = () => {
        setBasicModal2(!basicModal2)
        setPayAmount(0)
        setDescription('')
        setSelectedDebtOption("CASH")
    };
    useEffect(() => {
        getCustomers()
    }, []);
    useEffect(() => {
        getCustomers()
    }, [searchCustomer]);

    async function getCustomers(page = 1) {
        const res = await instance(`/customer?search=${searchCustomer}&page=${page}`, "GET");
        if (res.data.ok) {
            setCustomers(res.data.data.content)
            setTotalPages(res.data.data.totalPages)
        } else {
            ErrorNotify(res.data.message)
        }
    }

    async function saveCustomer(e) {
        e.preventDefault()
        if (editeItem !== '') {
            const res = await instance(`/customer/${editeItem.id}`, "PUT", {name: customer, phone});
            if (res.data.ok) {
                SuccessNotify(res.data.message)
                toggleOpen()
                getCustomers()
                setCustomer('')
                setPhone('')
            } else {
                ErrorNotify(res.data.message)
            }
        } else {
            const res = await instance("/customer", "POST", {name: customer, phone});
            if (res.data.ok) {
                SuccessNotify(res.data.message)
                toggleOpen()
                getCustomers()
                setCustomer('')
                setPhone('')
            } else {
                ErrorNotify(res.data.message)
            }
        }
    }

    function editeCustomer(item) {
        toggleOpen()
        setEditeItem(item);
        setCustomer(item.name);
        setPhone(item.phone);
    }

    function payDebtOpen(item) {
        toggleOpen2()
        setCurrentItem(item)
    }

    async function payDebt(e) {
        e.preventDefault()
        const res = await instance(`/customer/pay-debt/${currentItem.id}`, "POST", {
            payAmount: payAmount,
            paymentMethod: selectedDebtOption,
            description: description
        });
        if (res.data.ok) {
            console.log(res.data.message)
            SuccessNotify(res.data.message)
            toggleOpen2()
            getCustomers()
            setPayAmount(0)
            setDescription('')
            setSelectedDebtOption("CASH")
        } else {
            ErrorNotify(res.data.message)
        }
    }

    return (
        <div>
            <Navbar/>
            <div style={{width: "100%", padding: 10, display: "flex", justifyContent: "space-between",flexWrap:"wrap"}}>
                <MDBBtn onClick={toggleOpen}>MIJOZ QO'SHISH</MDBBtn>
                <input value={searchCustomer} onChange={(e) => setSearchCustomer(e.target.value)} minLength={4}
                       required type="text" placeholder={'Mijozni Izlash'}
                       className={'form-control mb-2 mt-2'} style={{width:300}}/>
            </div>
            <div style={{width: "100%", overflowY: "auto"}}>
                <MDBTable align='middle'>
                    <MDBTableHead>
                        <tr>
                            <th scope='col'>ISM</th>
                            <th scope='col'>TELEFON RAQAM</th>
                            <th scope='col'>QARZ MIQDORI</th>
                            <th scope='col'>AMALLAR</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            customers?.map((item, index) => {
                                return <tr key={index}>
                                    <td>
                                        <p className='fw-bold mb-1'>{item.name}</p>
                                    </td>
                                    <td>
                                        <p className='fw-normal mb-1'>{item.phone}</p>
                                    </td>
                                    <td>
                                        {
                                            item?.debtAmount > 0 ?
                                                <MDBBadge color='danger' pill>
                                                    {format(item.debtAmount)} UZS
                                                </MDBBadge> :
                                                <MDBBadge color='success' pill>
                                                    Hozircha Yo'q
                                                </MDBBadge>
                                        }
                                    </td>
                                    <td>
                                        <MDBBtnGroup aria-label='Basic example'>
                                            <MDBBtn onClick={() => navigate(`/customer/${item?.id}/payment/history`)}
                                                    color={'primary'}>
                                                <MDBIcon fas icon="history"/>
                                            </MDBBtn>
                                            <MDBBtn onClick={() => editeCustomer(item)} color='link'>
                                                <MDBIcon fas icon="pen"/>
                                            </MDBBtn>
                                            {
                                                item.debtAmount > 0 ?
                                                    <MDBBtn onClick={() => payDebtOpen(item)} color='secondary'>
                                                        <MDBIcon fas icon="dollar-sign"/>
                                                    </MDBBtn> : ""
                                            }
                                        </MDBBtnGroup>
                                    </td>
                                </tr>
                            })
                        }
                    </MDBTableBody>
                </MDBTable>
                {totalPages > 1 ? <div style={{
                    marginTop: 20,
                    width: "100%",
                    padding: 7,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Pagination onChange={(event, page) => getCustomers(page, "")} count={totalPages} color="primary"/>
                </div> : ""}
            </div>

            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Mijoz qo'shish</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={saveCustomer} id={'save-customer'}>
                            <MDBModalBody>
                                <input value={customer} onChange={(e) => setCustomer(e.target.value)} minLength={4}
                                       required type="text" placeholder={'Xaridor ismi'}
                                       className={'form-control mb-3'}/>
                                <PhoneInput
                                    value={phone}
                                    onChange={p => setPhone(p)}
                                    inputClass={'w-100'}
                                    country={'uz'}
                                    onlyCountries={['uz']}
                                />
                            </MDBModalBody>
                        </form>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn type={'submit'} form={'save-customer'}>
                                SAQLASH
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal open={basicModal2} onClose={() => setBasicModal2(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Qarzni to'lash</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen2}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={payDebt} id={'pay-debt'}>
                            <MDBModalBody>
                                <input value={payAmount} onChange={(e) => setPayAmount(e.target.value)}
                                       required type="number" placeholder={"To'lanadigan summani kiriting"}
                                       className={'form-control mb-3'}/>
                                {payAmount > 0 ? <div><p style={{marginBottom: 2}}>TO'LOV TURINI TANLANG</p>
                                    <label style={styleRadioLabel}>
                                        <input required
                                               type="radio"
                                               value="CASH"
                                               checked={selectedDebtOption === "CASH"}
                                               onChange={handleDebtOptionChange}
                                        />
                                        NAQD </label>
                                    <label style={styleRadioLabel}>
                                        <input
                                            required
                                            type="radio"
                                            value="CARD"
                                            checked={selectedDebtOption === "CARD"}
                                            onChange={handleDebtOptionChange}
                                        />
                                        PLASTIK
                                    </label></div> : ""}
                                <textarea value={description} onChange={(e) => setDescription(e.target.value)}
                                          className={'form-control mt-3'} placeholder={'Tavsif'}/>
                            </MDBModalBody>
                        </form>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn type={'submit'} form={'pay-debt'}>
                                TO'LASH
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;

const styleRadioLabel = {
    marginTop: 7,
    display: "block",
    cursor: "pointer",
}