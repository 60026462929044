import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import instance from "../../utils/instance";
import {ErrorNotify} from "../../alerts";
import {MDBBadge, MDBBtn, MDBIcon, MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import format from "../../utils/instance/format";
import {Pagination} from "@mui/material";
import {BASE_URL} from "../../utils/instance/Base";

function Index(props) {
    const navigate = useNavigate()
    const {id} = useParams()
    const [customer, setCustomer] = useState(null)
    const [payments, setPayments] = useState([])
    const [totalPages, setTotalPages] = useState(1)

    async function getUserInfo() {
        const res = await instance(`/customer/${id}`, "GET").catch((err) => {
            console.error(err)
            navigate('/customers')
        });
        if (res?.data?.ok) {
            setCustomer(res?.data?.data)
        } else {
            ErrorNotify(res?.data?.message)
            navigate('/customers')
        }
    }

    async function getCustomerHistory(page = 1) {
        const res = await instance(`/customer/payment/history?customerId=${id}&page=${page}`, "GET");
        if (res?.data?.ok) {
            setPayments(res?.data?.data?.content)
            setTotalPages(res?.data?.data?.totalPages)
        } else {
            ErrorNotify(res?.data?.message)
        }
    }

    useEffect(() => {
        getUserInfo()
        getCustomerHistory()
    }, [id]);
    return (
        <div style={{width: "100%", padding: 15}}>
            <b style={{display: "block"}}><b style={{fontSize: 12}}>MIJOZ ISMI:</b> {customer?.name}</b>
            <b style={{display: "block"}}><b style={{fontSize: 12}}>MIJOZ RAQAMI:</b> {customer?.phone}</b>
            <b style={{fontSize: 12, display: "block"}}>MIJOZ QARZI: {customer?.debtAmount} UZS</b>
            {payments.length > 0 ?
                <MDBBtn href={`${BASE_URL}/file/customer-history?customerId=${id}`} className='me-1 mt-3'
                        color='success'>
                    YUKLAB OLISH <MDBIcon fas icon="file-excel"/>
                </MDBBtn> :
                <h6 className={'mt-3'}>To'lovlar tarixi mavud emas <MDBIcon fas icon="exclamation-triangle"/></h6>}
            <div style={{width: "100%", overflowY: "auto"}}>
                <MDBTable align='middle'>
                    <MDBTableHead>
                        <tr>
                            <th scope='col'>TO'LOV SUMMASI</th>
                            <th scope='col'>TO'LOV TAVSIFI</th>
                            <th scope='col'>STATUS</th>
                            <th scope='col'>TO'LOV VAQTI</th>
                            <th scope='col'>YUKLAB OLISH</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {payments?.map((item) => <tr key={item?.id}>
                            <td>
                                <p className='fw-bold mb-1'>{format(item?.amount)} UZS</p>
                                <p className='text-muted mb-0'>
                                    {item?.method === "CASH"
                                        ? "NAQD"
                                        : item?.method === "CARD"
                                            ? "PLASTIK"
                                            : "QARZ"
                                    }
                                </p>
                            </td>
                            <td>
                                <pre style={{fontWeight: "bold"}} className='fw-normal mb-1'>{item?.description}</pre>
                            </td>
                            <td>
                                {item?.status === "PAID" ? <MDBBadge color='success' pill>
                                    TO'LOV
                                </MDBBadge> : <MDBBadge color='danger' pill>
                                    QARZ
                                </MDBBadge>}
                            </td>
                            <td>
                                <MDBBadge color='info' pill>
                                    {new Date().toDateString()} / {new Date().toLocaleTimeString()}
                                </MDBBadge>
                            </td>
                            <td>
                                <MDBBtn color='link'
                                        href={`${BASE_URL}/file/check?customerId=${id}&transactionId=${item?.id}`}>
                                    <MDBIcon fas icon="file-download"/>
                                </MDBBtn>
                            </td>
                        </tr>)}
                    </MDBTableBody>
                </MDBTable>
            </div>
            {totalPages > 1 ?
                <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Pagination onChange={(event, page) => getCustomerHistory(page)} count={totalPages}
                                color="primary"/>
                </div> : ""}
        </div>
    );
}

export default Index;