import React, {useEffect, useState} from 'react';
import Navbar from "../../ui/Navbar";
import {
    MDBBadge,
    MDBBtn,
    MDBIcon,
    MDBModal, MDBModalBody, MDBModalContent,
    MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from 'mdb-react-ui-kit';
import instance from "../../utils/instance";
import {ErrorNotify, SuccessNotify} from "../../alerts";
import Select from "react-select";
import {Pagination} from "@mui/material";
import PhoneInput from "react-phone-input-2";

function Index(props) {
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [option, setOption] = useState(null)
    const [page, setPage] = useState(1)
    const [basicModal, setBasicModal] = useState(false);
    const [user, setUser] = useState(null)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [remember, setRemember] = useState(false)
    const [password, setPassword] = useState('')
    const toggleOpen = () => {
        setPhone('')
        setFirstName('')
        setLastName('')
        setBasicModal(!basicModal);
    }

    async function getUsers() {
        const res = await instance(`/user?page=${page}`, "GET");
        if (res.data.ok) {
            setUsers(res.data.data.content)
            setTotalPages(res.data.data.totalPages)
        } else {
            ErrorNotify(res.data.message)
        }
    }

    async function getRoles() {
        const res = await instance("/user/role", "GET");
        if (res.data.ok) {
            const map = res.data.data.map(item => ({
                value: item?.roleName,
                label: item?.roleName
            }));
            setRoles(map)
        }
    }

    useEffect(() => {
        getRoles()
        getUsers()
    }, [page]);

    async function changeRole() {
        const res = await instance(`/user/change-role/${option?.id}`, "PUT", option?.roles);
        if (res?.data?.ok) {
            SuccessNotify(res?.data?.message)
            getUsers()
        }
    }

    useEffect(() => {
        if (option !== null) {
            changeRole()
        }
    }, [option]);

    function updateUser(item) {
        setUser(item)
        toggleOpen()
        setFirstName(item?.firstName)
        setLastName(item?.lastName)
        setPhone(item?.phone)
    }

    async function handleUserUpdate(e) {
        e.preventDefault()
        const res = await instance(`/user/${user?.id}`, "PUT", {password, remember, firstName, lastName, phone});
        if (res?.data?.ok) {
            SuccessNotify(res?.data?.message)
            toggleOpen()
            getUsers()
        } else {
            ErrorNotify(res?.data?.message)
        }
    }

    return (
        <div>
            <Navbar/>
            <div style={{width: "100%", padding: 10}}>
                <div style={{width: "100%", overflowY: "auto"}}>
                    <MDBTable align='middle'>
                        <MDBTableHead>
                            <tr>
                                <th scope='col'>ISM/FAMILIYA</th>
                                <th scope='col'>TELEFON RAQAM</th>
                                <th scope='col'>RO'YXATDAN O'TGAN SANA</th>
                                <th scope='col'>ROLLARI</th>
                                <th scope='col'>TAHRIRLASH</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {
                                users?.map((item) => <tr key={item?.id}>
                                    <td>
                                        <div className='ms-3'>
                                            <p className='fw-bold mb-1'>{item?.firstName}</p>
                                            <p className='text-muted mb-0'>{item?.lastName}</p>
                                        </div>

                                    </td>
                                    <td>
                                        <p className='fw-normal mb-1'>{item?.phone}</p>
                                    </td>
                                    <td>
                                        <MDBBadge color='success' pill>
                                            {new Date(item?.date).toDateString()} / {new Date(item?.date).toLocaleTimeString()}
                                        </MDBBadge>
                                    </td>
                                    <td>
                                        <Select
                                            defaultValue={item?.roles?.map(r => ({
                                                value: r,
                                                label: r
                                            }))}
                                            isMulti
                                            name="colors"
                                            onChange={(e) => setOption({id: item?.id, roles: e})}
                                            options={roles}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </td>
                                    <td>
                                        <MDBBtn onClick={() => updateUser(item)} color='link' rounded size='sm'>
                                            <MDBIcon fas icon="pen"/>
                                        </MDBBtn>
                                    </td>
                                </tr>)
                            }

                        </MDBTableBody>
                    </MDBTable>
                </div>
                {totalPages > 1 ? <div style={{
                    width: "100%",
                    padding: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Pagination count={totalPages} onChange={(event, page) => setPage(page)} color="primary"/>
                </div> : ""}
            </div>

            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>FOYDALANUVCHINI TAHRIRLASH</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={handleUserUpdate} id={'update-user'}>
                            <MDBModalBody>
                                <input placeholder={"Ismni kiriting"} type="text" className={'form-control mb-3'}
                                       required
                                       value={firstName}
                                       onChange={(e) => setFirstName(e.target.value)}/>
                                <input placeholder={"Familiyani kiriting"} type="text" className={'form-control mb-3'}
                                       required
                                       value={lastName}
                                       onChange={(e) => setLastName(e.target.value)}/>
                                <PhoneInput
                                    inputClass={'w-100'}
                                    country={'uz'}
                                    value={phone}
                                    onlyCountries={['uz']}
                                    onChange={phone => setPhone(phone)}
                                />
                                <label style={{cursor: "pointer"}} className={'mt-3 w-100 d-flex'}>
                                    <input checked={remember} onChange={(e) => setRemember(e.target.checked)}
                                           type="checkbox" className={'form-check'}/>
                                    <b style={{fontSize: 14}}>Parolni yangilash</b>
                                </label>
                                {remember ?
                                    <input value={password} onChange={(e) => setPassword(e.target.value)} required
                                           minLength={8} type="text" className={'form-control mt-3'}
                                           placeholder={'Parolni kiriting'}/> : ""}
                            </MDBModalBody>
                        </form>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn type={'submit'} form={'update-user'}>SAQLASH</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;