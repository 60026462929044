import React, {useEffect, useState} from 'react';
import Navbar from "../../ui/Navbar";
import {
    MDBBtn,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle
} from 'mdb-react-ui-kit';
import instance from "../../utils/instance";
import {ErrorNotify, SuccessNotify} from "../../alerts";
import {MDBBadge, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';
import format, {formatDateYear} from "../../utils/instance/format";
import {DatePicker} from 'antd';

const {RangePicker} = DatePicker;

function Index(props) {
    const [basicModal, setBasicModal] = useState(false);
    const [types, setTypes] = useState([])
    const [costs, setCosts] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const toggleOpen = () => {
        setBasicModal(!basicModal);
        setSelectedOption("CASH")
        setAmount("")
        setType("")
        setDescription("")
        setCurrentCost(null)
    }
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedType, setSelectedType] = useState("")
    const [selectedOption, setSelectedOption] = useState("CASH");
    const [type, setType] = useState("");
    const [amount, setAmount] = useState("");
    const [description, setDescription] = useState("");
    const [currentCost, setCurrentCost] = useState(null)
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    async function getCostTypes() {
        const res = await instance("/cost-type", "GET");
        if (res?.data?.ok) {
            setTypes(res?.data?.data)
        }
    }

    useEffect(() => {
        getCostTypes()
    }, []);

    async function saveCost(e) {
        e.preventDefault()
        if (currentCost === null) {
            const res = await instance("/cost", "POST", {
                costTypeId: type,
                amount,
                method: selectedOption,
                description
            });
            if (res?.data?.ok) {
                await getCosts()
                SuccessNotify(res?.data?.message)
                toggleOpen()
            } else {
                ErrorNotify(res?.data?.message)
            }
        } else {
            const res = await instance(`/cost/${currentCost?.id}`, "PUT", {
                costTypeId: type,
                amount,
                method: selectedOption,
                description
            });
            if (res?.data?.ok) {
                await getCosts()
                SuccessNotify(res?.data?.message)
                toggleOpen()
            } else {
                ErrorNotify(res?.data?.message)
            }
        }
    }

    async function getCosts(page = 1) {
        if (selectedDate === null) {
            if (selectedType === "" || selectedType === "ALL") {
                const res = await instance(`/cost?page=${page}`, "GET");
                if (res?.data?.ok) {
                    setCosts(res?.data?.data?.content)
                    setTotalPages(res?.data?.data?.totalPages)
                }
            } else {
                const res = await instance(`/cost?page=${page}&costTypeId=${selectedType}`, "GET");
                if (res?.data?.ok) {
                    setCosts(res?.data?.data?.content)
                    setTotalPages(res?.data?.data?.totalPages)
                }
            }
        } else {
            if (selectedType === "" || selectedType === "ALL") {
                const res = await instance(`/cost?page=${page}&startDate=${selectedDate[0]}&endDate=${selectedDate[1]}`, "GET");
                if (res?.data?.ok) {
                    setCosts(res?.data?.data?.content)
                    setTotalPages(res?.data?.data?.totalPages)
                }
            } else {
                const res = await instance(`/cost?page=${page}&costTypeId=${selectedType}&startDate=${selectedDate[0]}&endDate=${selectedDate[1]}`, "GET");
                if (res?.data?.ok) {
                    setCosts(res?.data?.data?.content)
                    setTotalPages(res?.data?.data?.totalPages)
                }
            }
        }
    }

    useEffect(() => {
        getCosts()
    }, [selectedType, selectedDate]);

    function editCost(item) {
        toggleOpen()
        setCurrentCost(item)
        setType(item?.typeId)
        setAmount(item?.amount)
        setSelectedOption(item?.method)
        setDescription(item?.description)
    }

    return (
        <div><Navbar/>
            <div style={{width: "100%", padding: 10}}>
                <MDBBtn className={'mb-3'} onClick={toggleOpen}>XARAJAT QO'SHISH <MDBIcon fas icon="plus"/></MDBBtn>
                <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)} style={{width: 300}}
                        className={'form-select mb-3'}>
                    <option value="" selected disabled>XARAJAT TURINI TANLANG</option>
                    <option value="ALL">BARCHA TURLAR</option>
                    {types?.map((item) => {
                        return <option key={item?.id} value={item?.id}>{item?.name}</option>
                    })}
                </select>
                <RangePicker onChange={(values) => {
                    let a = []
                    if (values !== null) {
                        values.map(item => {
                            a.push(formatDateYear(item))
                        })
                        setSelectedDate(a)
                    } else {
                        setSelectedDate(null)
                    }
                }
                } className={'mb-3'}/>
                {costs?.length === 0 ? <h4>Xarajat mavjud emas!</h4> : <div style={{width: "100%", overflowY: "auto"}}>

                    <MDBTable align='middle'>
                        <MDBTableHead>
                            <tr>
                                <th scope='col'>XARAJAT QILUVCHI</th>
                                <th scope='col'>XARAJAT MIQDORI</th>
                                <th scope='col'>TO'LOV USULI</th>
                                <th scope='col'>XARAJAT TURI</th>
                                <th scope='col'>TAVSIF</th>
                                <th scope='col'>TAHRIRLASH</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {costs?.map((item) => <tr key={item?.id}>
                                    <td>
                                        <p className='fw-bold mb-1'>{item?.createdFirstName}</p>
                                        <p className='text-muted mb-0'>{item?.createdLastName}</p>
                                    </td>
                                    <td>
                                        <p className='fw-normal mb-1'>{format(item?.amount)} UZS</p>
                                    </td>
                                    <td>
                                        {item?.method === "CASH" ? <MDBBadge color='primary' pill>
                                            NAQD
                                        </MDBBadge> : <MDBBadge color='secondary' pill>
                                            PLASTIK
                                        </MDBBadge>}
                                    </td>
                                    <td>{item?.typeName}</td>
                                    <td>
                                        <pre>{item?.description}</pre>
                                    </td>
                                    <td>
                                        <MDBBtn onClick={() => editCost(item)} color='link' rounded size='sm'>
                                            <MDBIcon fas icon="pen"/>
                                        </MDBBtn>
                                    </td>
                                </tr>
                            )}
                        </MDBTableBody>
                    </MDBTable>
                </div>}
            </div>

            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>XARAJAT</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <form id={'save-cost'} onSubmit={saveCost}>
                            <MDBModalBody>
                                <select value={type} onChange={(e) => setType(e.target.value)}
                                        className={'form-select mb-3'} required>
                                    <option value="" disabled selected>XARAJAT TURINI TANLANG</option>
                                    {types?.map((item) => <option value={item?.id}
                                                                  key={item?.id}>{item?.name}</option>)}
                                </select>
                                <input value={amount} onChange={(e) => setAmount(e.target.value)} type="number"
                                       placeholder={'Xarajat miqdori'} className={'form-control mb-3'}
                                       required/>
                                <div className={'mb-3'}>
                                    <p style={{marginBottom: 1}}>TO'LOV TURI</p>
                                    <label style={styleRadioLabel}>
                                        <input required
                                               type="radio"
                                               value="CASH"
                                               checked={selectedOption === "CASH"}
                                               onChange={handleOptionChange}
                                        />
                                        NAQD </label>
                                    <label style={styleRadioLabel}>
                                        <input
                                            required
                                            type="radio"
                                            value="CARD"
                                            checked={selectedOption === "CARD"}
                                            onChange={handleOptionChange}
                                        />
                                        PLASTIK
                                    </label></div>
                                <textarea value={description} onChange={(e) => setDescription(e.target.value)}
                                          className={'form-control'} style={{minHeight: 100}}
                                          placeholder={'Tavsif kiriting'}></textarea>
                            </MDBModalBody>
                        </form>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn type={'submit'} form={'save-cost'}>SAQLASH</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;


const styleRadioLabel = {
    marginTop: 7,
    display: "block",
    cursor: "pointer",
}