import React, {useEffect, useState, Component} from 'react';
import Navbar from "../../ui/Navbar";
import format, {formatDateYear} from "../../utils/instance/format";
import {DatePicker} from 'antd';
import './index.css'
import {MDBBtn, MDBIcon} from "mdb-react-ui-kit";
import instance from "../../utils/instance";
import {MDBBadge, MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';
import {PHOTO_URL} from "../../utils/instance/Base";
import CanvasJSReact from '@canvasjs/react-charts';
import {Pagination} from "@mui/material";

const {RangePicker} = DatePicker;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;


function Index(props) {
    const [selectedDate, setSelectedDate] = useState(null)
    const [info, setInfo] = useState(null)
    const [bestProducts, setBestProducts] = useState([])
    const [tradeProfitData, setTradeProfitData] = useState('')
    const [bTotalPages, setBTotalPages] = useState(1)

    async function getInfo() {
        if (selectedDate === null) {
            const res = await instance("/report/info", "GET");
            if (res?.data?.ok) {
                setInfo(res?.data?.data)
            }
        } else {
            const res = await instance(`/report/info?startDate=${selectedDate[0]}&endDate=${selectedDate[1]}`, "GET");
            if (res?.data?.ok) {
                setInfo(res?.data?.data)
            }
        }
    }

    async function getBestProducts(page = 1) {
        const res = await instance(`/report/best-sale-products?page=${page}`);
        if (res?.data?.ok) {
            setBestProducts(res?.data?.data?.content)
            setBTotalPages(res?.data?.data?.totalPages)
        }
    }

    async function getTradeProfit() {
        if (selectedDate === null) {
            const res = await instance(`/report/trade-profit`);
            if (res?.data?.ok) {
                setTradeProfitData(res?.data?.data)
            }
        } else {
            const res = await instance(`/report/trade-profit?startDate=${selectedDate[0]}&endDate=${selectedDate[1]}`);
            if (res?.data?.ok) {
                setTradeProfitData(res?.data?.data)
            }
        }
    }

    useEffect(() => {
        getBestProducts()
    }, []);
    useEffect(() => {
        getInfo()
        getTradeProfit()
    }, [selectedDate]);

    const options = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light1",
        title: {
            text: "Savdodan tushgan foydalar: "
        },
        data: [{
            type: "pie",
            startAngle: -90,
            dataPoints: [
                {y: (tradeProfitData.totalTradeProfit * tradeProfitData.CardTradeProfit) / 100, label: "PLASTIK"},
                {y: (tradeProfitData.totalTradeProfit * tradeProfitData.DebtTradeProfit) / 100, label: "QARZ"},
                {y: (tradeProfitData.totalTradeProfit * tradeProfitData.CashTradeProfit) / 100, label: "NAQT"}
            ]
        }]
    }
    return (
        <div>
            <Navbar/>
            <div style={{width: "100%", padding: 10}}>
                <RangePicker onChange={(values) => {
                    let a = []
                    if (values !== null) {
                        values.map(item => {
                            a.push(formatDateYear(item))
                        })
                        setSelectedDate(a)
                    } else {
                        setSelectedDate(null)
                    }
                }
                } className={'mb-3'}/>
                <div className={'w-100 d-flex align-items-center flex-wrap gap-3'}>
                    <div className="repo-card">
                        <h5>MIJOZLAR SONI: </h5>
                        <div className={'w-100 d-flex align-items-center justify-content-between'}>
                            <h1>{info?.customerCount}</h1>
                            <MDBBtn href={'/customers'} color={'link'}>
                                <MDBIcon far icon="arrow-alt-circle-right"/>
                            </MDBBtn>
                        </div>
                    </div>
                    <div className="repo-card">
                        <h5>SAVDOLAR SONI: </h5>
                        <div className={'w-100 d-flex align-items-center justify-content-between'}>
                            <h1>{info?.tradeCount}</h1>
                            <MDBBtn href={'/trades'} color={'link'}>
                                <MDBIcon far icon="arrow-alt-circle-right"/>
                            </MDBBtn>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: 20}}>
                    <CanvasJSChart options={options}/>
                    <ul className={'d-flex justify-content-between flex-wrap'} style={{listStyle:"none"}}>
                        <li>NAQD: {format(tradeProfitData.CashTradeProfit)} UZS</li>
                        <li>PLASTIK: {format(tradeProfitData.CardTradeProfit)} UZS</li>
                        <li>QARZ: {format(tradeProfitData.DebtTradeProfit)} UZS</li>
                        <b>JAMI SUMMA: {format(tradeProfitData.totalTradeProfit)} UZS</b>
                    </ul>
                </div>
                <div style={{width: "100%", padding: 10, overflowY: "auto"}}>
                    <h5 className={'text-center mt-4 mb-3'}>OMMABOP MAHSULOTLAR</h5>
                    <MDBTable align='middle'>
                        <MDBTableHead>
                            <tr>
                                <th scope='col'>MAHSULOT NOMI</th>
                                <th scope='col'>MAHSULOT NARXI</th>
                                <th scope='col'>MAHSULOT MIQDORI</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {bestProducts?.map((item) => <tr key={item?.id}>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <img
                                            src={PHOTO_URL + item?.photo}
                                            alt=''
                                            style={{width: '45px', height: '45px'}}
                                            className='rounded-circle'
                                        />
                                        <div className='ms-3'>
                                            <p className='fw-bold mb-1'>{item?.name}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className='fw-normal mb-1'>{format(item?.price)} UZS</p>
                                </td>
                                <td>
                                    <MDBBadge color='success' pill>
                                        {item?.quantity}
                                    </MDBBadge>
                                </td>
                            </tr>)}
                        </MDBTableBody>
                    </MDBTable>
                </div>
                {bTotalPages > 1 ? <div className={'w-100 p-2 d-flex align-items-center justify-content-center'}>
                    <Pagination count={bTotalPages} onChange={(event, page) => getBestProducts(page)} color="primary"/>
                </div> : ""}
            </div>
        </div>
    );
}

export default Index;