import React, {useEffect, useState} from 'react';
import Navbar from "../../ui/Navbar";
import Select from 'react-select'
import {
    MDBBtn,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from 'mdb-react-ui-kit';

import './index.css'

import PhoneInput from 'react-phone-input-2'
import instance from "../../utils/instance";
import {ErrorNotify, SuccessNotify} from "../../alerts";
import format from "../../utils/instance/format";


function Index(props) {
    const [basicModal, setBasicModal] = useState(false);
    const [customer, setCustomer] = useState('')
    const [phone, setPhone] = useState('')
    const [customers, setCustomers] = useState([])
    const [products, setProducts] = useState([])
    const [searchCustomer, setSearchCustomer] = useState('')
    const [searchProduct, setSearchProduct] = useState('')
    const [selectCustomer, setSelectCustomer] = useState('')
    const [selectedProducts, setSelectedProducts] = useState([])
    const [debtSum, setDebtSum] = useState(0)
    const toggleOpen = () => {
        setBasicModal(!basicModal);
        setCustomer('')
        setPhone('')
    }

    const [sellModal, setSellModal] = useState(false);

    const toggleOpenSell = () => {
        setDebtSum(0)
        setSellModal(!sellModal)
    };
    const [selectedOption, setSelectedOption] = useState("CASH");
    const [selectedDebtOption, setSelectedDebtOption] = useState("CASH");

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleDebtOptionChange = (event) => {
        setSelectedDebtOption(event.target.value);
    };

    async function getCustomers() {
        const res = await instance(`/customer?search=${searchCustomer}`, "GET");
        if (res.data.ok) {
            const options = res.data.data.content.map(item => ({
                value: item.id,
                label: item.name,
            }));
            setCustomers(options)
        } else {
            ErrorNotify(res.data.message)
        }
    }

    useEffect(() => {
        getCustomers()
    }, [searchCustomer]);
    useEffect(() => {
        getProducts()
    }, [searchProduct]);

    async function getProducts() {
        const res = await instance(`/product?search=${searchProduct}`, "GET");
        if (res.data.ok) {
            const options = res.data.data.content.map(item => ({
                value: item.id,
                label: item.name
            }));
            setProducts(options)
        } else {
            ErrorNotify(res.data.message)
        }
    }

    useEffect(() => {
        getCustomers()
        getProducts()
    }, []);

    async function saveCustomer(e) {
        e.preventDefault()
        const res = await instance("/customer", "POST", {name: customer, phone});
        if (res.data.ok) {
            getCustomers()
            setSelectCustomer(res.data.data.id)
            SuccessNotify(res.data.message)
            toggleOpen()
        } else {
            ErrorNotify(res.data.message)
        }
    }

    const changeProduct = async (selectedProduct) => {
        if (selectedProduct !== null) {
            const alreadyExists = selectedProducts.some(item => item.product.value === selectedProduct.value);
            if (!alreadyExists) {
                const res = await instance(`/product?id=${selectedProduct.value}`);
                if (res.data.ok) {
                    const selectedEntry = {
                        product: selectedProduct,
                        id: selectedProduct?.value,
                        quantity: 1,
                        price: res.data.data.sellPrice,
                        category: res.data.data.categoryName
                    };
                    setSelectedProducts(prevProducts => [...prevProducts, selectedEntry]);
                } else {
                    ErrorNotify(res.data.message);
                }
            }
        }
    };

    function handleDelete(index) {
        const updatedProducts = [...selectedProducts.slice(0, index), ...selectedProducts.slice(index + 1)];
        setSelectedProducts(updatedProducts);
    }

    function handlePriceChange(index, value) {
        setSelectedProducts(prevProducts => {
            const updatedProducts = [...prevProducts];
            updatedProducts[index] = {
                ...updatedProducts[index],
                price: value
            };
            return updatedProducts;
        });
    }

    function handleQuantityChange(index, value) {
        if (value >= 1 || value === "") {
            setSelectedProducts(prevProducts => {
                const updatedProducts = [...prevProducts];
                updatedProducts[index] = {
                    ...updatedProducts[index],
                    quantity: value
                };
                return updatedProducts;
            });
        }
    }

    function handleQuantityClick(index, type) {
        if (type === "PLUS") {
            setSelectedProducts(prevProducts => {
                const updatedProducts = [...prevProducts];
                updatedProducts[index] = {
                    ...updatedProducts[index],
                    quantity: parseInt(updatedProducts[index].quantity) + 1
                };
                return updatedProducts;
            });
        } else {
            setSelectedProducts(prevProducts => {
                const updatedProducts = [...prevProducts];
                updatedProducts[index] = {
                    ...updatedProducts[index],
                    quantity: Math.max(updatedProducts[index].quantity - 1, 1)
                };
                return updatedProducts;
            });
        }

    }

    const totalPrice = () => {
        if (selectedProducts.length === 0) {
            return 0;
        }
        return selectedProducts.reduce((total, product) => {
            const productTotal = product.quantity * product.price;
            return total + productTotal;
        }, 0);
    }

    function handleSubmit() {
        const isProductsValid = selectedProducts.every(item => {
            return (
                item.price !== "" &&
                item.product.label !== "" &&
                item.quantity !== ""
            );

        });
        if (!isProductsValid) {
            ErrorNotify("Iltimos, har bir mahsulot uchun barcha kerakli maydonlarni to'ldiring!");
            return;
        } else if (selectCustomer === "") {
            ErrorNotify("Xaridorni tanlang!");
            return;
        } else {
            toggleOpenSell()
        }

    }

    async function verifyTrade(e) {
        e.preventDefault()
        const res = await instance("/trade", "POST", {
            products: selectedProducts,
            customerId: selectCustomer.value,
            method: selectedOption,
            paidSum: debtSum,
            totalSum: totalPrice(),
            firstPayMethod: selectedDebtOption
        })
        if (res.data.ok) {
            SuccessNotify(res.data.message)
            setSelectedOption("CASH")
            setSelectedDebtOption("CASH")
            setSelectedProducts([])
            setSelectCustomer('')
            setDebtSum(0)
            toggleOpenSell()
        } else {
            ErrorNotify(res.data.message)
        }
    }

    return (
        <div>
            <Navbar/>
            <div style={{width: "100%", padding: 5, height: "90vh"}}>
                <div style={{width: "100%", height: "100%", border: "1px solid #bfbfbf", padding: 5, borderRadius: 10}}>
                    <div id={'trade-container'}>
                        <div id={'left'}>
                            <Select defaultValue={selectCustomer} onInputChange={(e) => setSearchCustomer(e)}
                                    onChange={(e) => setSelectCustomer(e)}
                                    placeholder={"Mijozni tanlash yoki izlash..."} className={'w-100'}
                                    options={customers}/>
                            <MDBBtn onClick={toggleOpen}><MDBIcon fas icon="plus"/></MDBBtn>
                        </div>
                        <div id={'right'}>
                            <Select onInputChange={(e) => setSearchProduct(e)}
                                    onChange={(e) => changeProduct(e)}
                                    placeholder={"Mahsulotni izlash..."} className={'w-100'}
                                    options={products}/>
                        </div>
                    </div>
                    {selectedProducts.length > 0 ? <div style={{
                        width: "100%",
                        padding: 5,
                        border: "1px solid #bfbfbf",
                        borderRadius: 6,
                        marginTop: 10,
                        overflowY: "auto",
                        overflowX: "auto",
                        height: 460
                    }}>
                        <MDBTable align='middle'>
                            <MDBTableHead>
                                <tr>
                                    <th scope='col'><MDBIcon fas icon="list-ol"/></th>
                                    <th scope='col'>Mahsulot nomi</th>
                                    <th scope='col'>Mahsulot soni</th>
                                    <th scope='col'>Mahsulot narxi</th>
                                    <th scope='col'>Jami narxi</th>
                                    <th scope='col'><MDBIcon fas icon="trash"/></th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {selectedProducts?.map((item, index) => {
                                    return <tr key={index}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            <p className='fw-normal mb-1'>{item?.product?.label}</p>
                                            <p className='text-muted mb-0'>{item?.category}</p>
                                        </td>
                                        <td>
                                            <div style={{
                                                width: 200,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-evenly",
                                                height: 50
                                            }}>
                                                <button onClick={() => handleQuantityClick(index, "MINUS")}
                                                        className={'btn btn-danger'}>
                                                    <MDBIcon fas icon="minus"/>
                                                </button>
                                                <input onChange={(e) => handleQuantityChange(index, e.target.value)}
                                                       value={item?.quantity} placeholder={'Mahsulot soni'}
                                                       type="number"
                                                       className={'form-control'}/>
                                                <button onClick={() => handleQuantityClick(index, "PLUS")}
                                                        className={'btn btn-success'}>
                                                    <MDBIcon fas icon="plus"/>
                                                </button>
                                            </div>
                                        </td>
                                        <td>
                                            <input onChange={(e) => handlePriceChange(index, e.target.value)}
                                                   value={item?.price} className={'form-control'} style={{width: 150}}
                                                   type="number"
                                                   placeholder={'Mahsulot narxi'}/>
                                        </td>
                                        <td>
                                            {format(item?.quantity * item?.price)} UZS
                                        </td>
                                        <td>
                                            <MDBBtn onClick={() => handleDelete(index)} color={'danger'}>
                                                <MDBIcon fas icon="trash"/>
                                            </MDBBtn>
                                        </td>
                                    </tr>
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    </div> : ""}
                    {selectedProducts.length > 0 ? <div
                        style={{
                            width: '100%',
                            height: 70,
                            padding: 10,
                            border: "1px solid #bfbfbf",
                            borderRadius: 10,
                            marginTop: 10,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                        <h5>JAMI: {format(totalPrice())} UZS</h5>
                        <MDBBtn onClick={handleSubmit}>TAYYOR</MDBBtn>
                    </div> : ""}
                </div>
            </div>

            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>XARIDOR QO'SHISH</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={saveCustomer} id={'save-customer'}>
                            <MDBModalBody>
                                <input value={customer} onChange={(e) => setCustomer(e.target.value)} minLength={4}
                                       required type="text" placeholder={'Xaridor ismi'}
                                       className={'form-control mb-3'}/>
                                <PhoneInput
                                    value={phone}
                                    onChange={p => setPhone(p)}
                                    inputClass={'w-100'}
                                    country={'uz'}
                                    onlyCountries={['uz']}
                                />
                            </MDBModalBody>
                        </form>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn type={'submit'} form={'save-customer'}>
                                SAQLASH
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


            <MDBModal open={sellModal} onClose={() => setSellModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>KERAKLI SOZLAMALARNI KIRITING</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpenSell}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={verifyTrade} id={'verify-trade'}>
                            <MDBModalBody>
                                <p style={{marginBottom: 2}}>MIJOZ: {selectCustomer?.label}</p>
                                <p>JAMI TO'LOV SUMMASI: {totalPrice()} UZS</p>
                                <p style={{marginBottom: 2}}>TO'LOV TURINI TANLANG</p>
                                <label style={styleRadioLabel}>
                                    <input required
                                           type="radio"
                                           value="CASH"
                                           checked={selectedOption === "CASH"}
                                           onChange={handleOptionChange}
                                    />
                                    NAQD </label>
                                <label style={styleRadioLabel}>
                                    <input
                                        required
                                        type="radio"
                                        value="CARD"
                                        checked={selectedOption === "CARD"}
                                        onChange={handleOptionChange}
                                    />
                                    PLASTIK
                                </label>
                                <label style={styleRadioLabel}>
                                    <input
                                        required
                                        type="radio"
                                        value="DEBT"
                                        checked={selectedOption === "DEBT"}
                                        onChange={handleOptionChange}
                                    />
                                    QARZ
                                </label>
                                {selectedOption === "DEBT" ? <label style={{marginTop: 10, width: "100%"}}>
                                    <p style={{marginBottom: 1, fontSize: 12}}>BIRINCHI TO'LOV</p>
                                    <input value={debtSum} onChange={(e) => setDebtSum(e.target.value)} required
                                           className={'form-control'} type="number"
                                           placeholder={"TO'LOV SUMMASINI KIRITING"}/>
                                </label> : ""}
                                {debtSum > 0 ? <div><p style={{marginBottom: 2}}>TO'LOV TURINI TANLANG</p>
                                    <label style={styleRadioLabel}>
                                        <input required
                                               type="radio"
                                               value="CASH"
                                               checked={selectedDebtOption === "CASH"}
                                               onChange={handleDebtOptionChange}
                                        />
                                        NAQD </label>
                                    <label style={styleRadioLabel}>
                                        <input
                                            required
                                            type="radio"
                                            value="CARD"
                                            checked={selectedDebtOption === "CARD"}
                                            onChange={handleDebtOptionChange}
                                        />
                                        PLASTIK
                                    </label></div> : ""}
                            </MDBModalBody>
                        </form>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpenSell}>
                                BEKOR QILISH
                            </MDBBtn>
                            <MDBBtn type="submit" form={'verify-trade'}>SOTISH</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;

const styleRadioLabel = {
    marginTop: 7,
    display: "block",
    cursor: "pointer",
}