import React, {useEffect} from 'react';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Login from "./components/pages/Login";
import axios from "axios";
import {BASE_URL} from "./components/utils/instance/Base";
import Home from "./components/pages/Home";
import Category from "./components/pages/Category";
import {Toaster} from "react-hot-toast";
import Products from "./components/pages/Products";
import Trade from "./components/pages/Trade";
import Customer from "./components/pages/Customer";
import User from "./components/pages/User";
import CustomerPaymentHistory from "./components/pages/CustomerPaymentHistory";
import Trades from "./components/pages/Trades";
import Costs from "./components/pages/Costs";
import CostType from "./components/pages/CostType";
import Order from "./components/pages/Order";
import Banner from "./components/pages/Banner";

function App(props) {
    const navigate = useNavigate()
    const location = useLocation();
    const permissions = [
        {url: "/", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER"]},
        {url: "/category", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER"]},
        {url: "/products", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER"]},
        {url: "/trade", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER"]},
        {url: "/trades", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER"]},
        {url: "/customers", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER"]},
        {url: "/costs", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER"]},
        {url: "/orders", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER"]},
        {url: "/cost-type", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SELLER"]},
        {url: "/users", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]},
        {url: "/banners", roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]},
    ];
    useEffect(() => {
        hasPermissions()
    }, [location.pathname]);

    function hasPermissions() {
        let count = 0;
        permissions.map((item, index) => {
            if (item.url === location.pathname) {
                count = count + 1;
            }
        });
        if (count === 1) {
            if (localStorage.getItem("@lsh_id_session") !== null) {
                axios({
                    url: BASE_URL + "/user/me",
                    method: "GET",
                    headers: {
                        Authorization: localStorage.getItem("@lsh_id_session"),
                    },
                })
                    .then((res) => {
                        let s = false;
                        permissions.map((item) => {
                            if (item.url === location.pathname) {
                                res.data.data.roles.map((i1) => {
                                    if (item.roles.includes(i1)) {
                                        s = true;
                                    }
                                });
                            }
                        });
                        if (!s) {
                            navigate("/login");
                        }
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            axios({
                                url:
                                    BASE_URL +
                                    "/auth/refresh?token=" +
                                    localStorage.getItem("@lsh_id_refresh_session"),
                                method: "POST",
                            })
                                .then((res) => {
                                    localStorage.setItem("@lsh_id_session", res.data.data);
                                    window.location.reload();
                                })
                                .catch((err) => {
                                    navigate("/login");
                                    localStorage.clear();
                                });
                        }
                    });
            } else {
                navigate("/login");
            }
        }
    }

    return (
        <div>
            <Toaster position="top-right"
                     reverseOrder={false}/>
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/category'} element={<Category/>}/>
                <Route path={'/products'} element={<Products/>}/>
                <Route path={'/trade'} element={<Trade/>}/>
                <Route path={'/trades'} element={<Trades/>}/>
                <Route path={'/customers'} element={<Customer/>}/>
                <Route path={'/customer/:id/payment/history'} element={<CustomerPaymentHistory/>}/>
                <Route path={'/users'} element={<User/>}/>
                <Route path={'/orders'} element={<Order/>}/>
                <Route path={'/banners'} element={<Banner/>}/>
                <Route path={'/costs'} element={<Costs/>}/>
                <Route path={'/cost-type'} element={<CostType/>}/>
                <Route path={'/login'} element={<Login/>}/>
            </Routes>
        </div>
    );
}

export default App;