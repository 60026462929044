import React, {useEffect, useState} from 'react';
import Navbar from "../../ui/Navbar";
import Select from "react-select";
import instance from "../../utils/instance";
import {ErrorNotify} from "../../alerts";
import format, {formatDateYear} from "../../utils/instance/format";
import {MDBBadge, MDBBtn, MDBIcon, MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import {Pagination} from "@mui/material";
import {DatePicker} from 'antd';

const {RangePicker} = DatePicker;

function Index(props) {
    const [selectCustomer, setSelectCustomer] = useState('')
    const [searchCustomer, setSearchCustomer] = useState('')
    const [customers, setCustomers] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const [trades, setTrades] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [basicModal, setBasicModal] = useState(false);
    const [products, setProducts] = useState([])
    const toggleOpen = () => setBasicModal(!basicModal);

    async function getCustomers() {
        const res = await instance(`/customer?search=${searchCustomer}`, "GET");
        if (res.data.ok) {
            const options = res.data.data.content.map(item => ({
                value: item.id,
                label: item.name,
            }));
            options.push({value: "", label: "Barcha xaridorlar"});
            setCustomers(options);

        } else {
            ErrorNotify(res.data.message)
        }
    }

    useEffect(() => {
        getCustomers()
    }, [searchCustomer]);

    async function getTrades(page = 1) {
        if (selectedDate === null) {
            if (selectCustomer) {
                const res = await instance(`/trade?page=${page}&customerId=${selectCustomer?.value}`);
                if (res.data.ok) {
                    setTrades(res.data.data.content)
                    setTotalPages(res.data.data.totalPages)
                }
            } else {
                const res = await instance(`/trade?page=${page}`);
                if (res.data.ok) {
                    setTrades(res.data.data.content)
                    setTotalPages(res.data.data.totalPages)
                }
            }
        } else {
            if (selectCustomer) {
                const res = await instance(`/trade?page=${page}&customerId=${selectCustomer?.value}&startDate=${selectedDate[0]}&endDate=${selectedDate[1]}`);
                if (res.data.ok) {
                    setTrades(res.data.data.content)
                    setTotalPages(res.data.data.totalPages)
                }
            } else {
                const res = await instance(`/trade?page=${page}&startDate=${selectedDate[0]}&endDate=${selectedDate[1]}`);
                if (res.data.ok) {
                    setTrades(res.data.data.content)
                    setTotalPages(res.data.data.totalPages)
                }
            }
        }
    }

    useEffect(() => {
        getTrades()
    }, [selectCustomer, selectedDate]);

    async function getProducts(id) {
        const res = await instance(`/trade/products/${id}`, "GET");
        if (res?.data?.ok) {
            setProducts(res?.data?.data)
            toggleOpen()
        } else {
            ErrorNotify(res?.data?.message)
        }
    }

    return (
        <div>
            <Navbar/>
            <div className={'w-100 p-2 d-flex align-items-center justify-content-between '} style={{flexWrap: "wrap"}}>
                <Select defaultValue={selectCustomer} onInputChange={(e) => setSearchCustomer(e)}
                        onChange={(e) => setSelectCustomer(e)} className={'mb-3'}
                        placeholder={"Mijozni tanlash yoki izlash..."}
                        options={customers}/>
                <RangePicker onChange={(values) => {
                    let a = []
                    if (values !== null) {
                        values.map(item => {
                            a.push(formatDateYear(item))
                        })
                        setSelectedDate(a)
                    } else {
                        setSelectedDate(null)
                    }
                }
                } className={'mb-3'}/>
            </div>
            <div style={{width: "100%", padding: 10, overflowY: "auto"}}>
                <MDBTable align='middle'>
                    <MDBTableHead>
                        <tr>
                            <th scope='col'>XARIDOR</th>
                            <th scope='col'>TO'LANGAN SUMMA</th>
                            <th scope='col'>QARZ SUMMASI</th>
                            <th scope='col'>STATUS</th>
                            <th scope='col'>TO'LOV TURI</th>
                            <th scope='col'>JAMI TO'LOV SUMMASI</th>
                            <th scope='col'>JAMI FOYDA SUMMASI</th>
                            <th scope='col'>MAHSULOTLAR RO'YXATI</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            trades?.map((item) => <tr key={item?.id}>
                                    <td>
                                        {item?.customerName ? <div className='ms-3'>
                                            <p className='fw-bold mb-1'>{item?.customerName}</p>
                                            <p className='text-muted mb-0'>{item?.customerPhone}</p>
                                        </div> : <div className='ms-3'>
                                            <p className='fw-bold mb-1'>ONLINE XARID</p>
                                        </div>}
                                    </td>
                                    <td>
                                        <p className='fw-normal mb-1'>{format(item?.paidSum)} UZS</p>
                                    </td>
                                    <td>
                                        <p className='fw-normal mb-1'>{format(item?.debtSum)} UZS</p>
                                    </td>
                                    <td>
                                        {
                                            item?.status === "UNPAID"
                                                ? <MDBBadge color='danger' pill>
                                                    TO'LANMAGAN
                                                </MDBBadge>
                                                : item?.status === "PAID"
                                                    ? <MDBBadge color='success' pill>
                                                        TO'LANGAN
                                                    </MDBBadge>
                                                    : <MDBBadge color='warning' pill>
                                                        QISMAN TO'LANGAN
                                                    </MDBBadge>
                                        }
                                    </td>
                                    <td>{item?.method === "CARD"
                                        ? "PLASTIK"
                                        : item?.method === "CASH"
                                            ? "NAQD"
                                            : "QARZ"}</td>
                                    <td>{format(item?.totalSum)} UZS</td>
                                    <td>{format(item?.totalProfit)} UZS</td>
                                    <td>
                                        <MDBBtn onClick={() => getProducts(item?.id)} color='link' rounded size='sm'>
                                            <MDBIcon fas icon="info-circle"/>
                                        </MDBBtn>
                                    </td>
                                </tr>
                            )
                        }
                    </MDBTableBody>
                </MDBTable>
                {totalPages > 1 ? <div style={{
                    width: "100%",
                    padding: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Pagination count={totalPages} onChange={(event, page) => getTrades(page)} color="primary"/>
                </div> : ""}
            </div>

            <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>MAHSULOTLAR</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody style={{overflowY: "auto"}}>
                            <MDBTable>
                                <MDBTableHead light>
                                    <tr>
                                        <th scope='col'>#</th>
                                        <th scope='col'>MAHSULOT NOMI</th>
                                        <th scope='col'>SOTILGAN NARXI</th>
                                        <th scope='col'>SOTILGAN MIQDORI</th>
                                        <th scope='col'>JAMI SUMMA</th>
                                        <th scope='col'>JAMI FOYDA</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {products?.map((item, index) => <tr key={item?.id}>
                                        <td>{index + 1}</td>
                                        <td>{item?.name}</td>
                                        <td>{format(item?.price)} UZS</td>
                                        <td>{item?.quantity}</td>
                                        <td>{format(item?.price * item?.quantity)} UZS</td>
                                        <td>{format(item?.profit)} UZS</td>
                                    </tr>)}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default Index;